<template>
  <!-- position:fixed -->
  <div class="content commonText">
    <orderAddress :addressList="addressList"></orderAddress>
    <timeDrawer @getChooseTime="getTime"></timeDrawer>
    <div>
      <van-field
        v-model="remark"
        rows="3"
        autosize
        label="留言"
        type="textarea"
        maxlength="250"
        placeholder="请输入留言(快递小哥看不到哦)"
        show-word-limit
      />
    </div>

    <titleModule :title="'订单信息'" class="maTo20" :slotWidth="'100'">
      <div
        v-for="(item, index) in cartList"
        :key="index"
        class="cartGoodsDiv"
        style="border-bottom: #f5f1f1 solid 0.01rem"
      >
        <div class="pflex centerDiv">
          <div class="leftImg pflex">
            <img :src="item.picurl" alt="" />
          </div>
          <div class="middleInfo cflex">
            <div>{{ item.name }}</div>
            <div class="orange maTo20">¥{{ item.vipPrice }}</div>
          </div>
          <div class="rightNum">数量 x {{ item.goodsNum }}</div>
        </div>
      </div>
    </titleModule>

    <!-- <titleModule :title="'可洗范围'" class="maTo20">
      <div class="orange" style="line-height: 1.5em">
        1.默认单件衣物价值均不超过2000元，如衣物价值过高请提前咨询客服，谢谢配合~<br />
        2.任意洗套餐须一次性使用完毕，不可拆分使用；<br />
        3.若实际送洗衣物数量少于套餐数量，则按照实际数量对应的套餐收费，退还差价。<br />
        4.若实际送洗衣物数量多于套餐数量，则按照已购套餐均价，补充差价。<br />
        【任意洗套餐分类】<br />
        1、全场任洗：普通衣鞋随意搭配组合，如羽绒服和雪地靴；<br />
        2、鞋任洗：仅限鞋子、靴子，不可洗护衣物； <br />
        【下列衣物不在套餐内】<br />
        1、皮草、皮衣、带真皮/PU皮类装饰的衣服、旗袍、婚纱/礼服、真丝、蚕丝、特殊涂层类衣服；<br />
        2、明星签名衣服、奢侈品、带五金配件装饰的衣服；<br />
        3、价值超2000元的贵重衣/鞋不可使用（部分地区可补价清洗，请详询客服）；<br />
        【补价项目】<br />
        1、加拿大鹅羽绒服需补差价35元/件（仅限京津冀鲁豫江浙沪皖粤闽）;<br />
        2、羊剪绒类衣服168元起、皮毛一体类衣服300元起、部分家居用品（详询客服）；<br />
        3、奢侈品衣鞋(价值超过2000元)需要按精洗135/件补差价（价值超过10000元的暂不服务）；<br />
        以上情况建议您提前告知客服，工厂实际判断不能洗的，衣鞋随单退回，收货后有劳您联系客服退还洗涤费。<br />
      </div>
    </titleModule> -->

    <!-- <titleModule :title="'服务地区/范围'" class="maTo20">
      <div style="line-height: 1.5em">
        <div class="mainColor" @click="toRange">点击查看范围</div>
      </div>
    </titleModule> -->

    <titleModule :title="'时效&运费说明'" class="maTo20">
      <div style="line-height: 1.5em">
        【洗护时效】<br />
        1、常规洗涤周期:普通衣物订单3-5天左右，含鞋子订单5-7天左右，难处理污渍/特殊问题的订单延时1-2天左右。<br />
        2、旺季洗衣周期:普通衣物订单5-7天左右，含鞋子订单7-10天左右，难处理污渍/特殊问题的订单延时1-2天左右。备注:洗涤周期按订单中涉及最高时长为准，暂无付费加急业务。<br />
        【运费说明】<br />
        1、订单满88元包邮，除西藏、青海、内蒙古及港澳台地区外全国可用；<br />
        2、因个人原因造成快递退回或订单取消，需要您承担来回运费20元;<br />
        3、因个人原因造成订单部分衣物不洗，不洗衣物金额大于等于订单金额50%，需要您承担运费10元;<br />
        4、因工厂原因无法清洗，则无需您承担运费。<br />
      </div>
    </titleModule>

    <titleModule :title="'洗护说明'" class="maTo20">
      <div style="line-height: 1.5em">
        1、关于包装：请简单包装衣物避免二次污染(入厂默认会丢弃，请勿使用高档材料)，送回时美家洁会有专用包装箱。<br />
        2、美家洁严格筛选各个地区高品质的中央洗衣工厂，采用顺丰到家取送，通过专业信息化系统，监控21道洗护流程，全流程可追溯，敬请放心；<br />
        3、工厂师傅用心洗护每一件衣物，在保障衣物质量的前提下尽力去除所有污渍，如仍未彻底去除，会放入标记污渍位置的蓝色美家洁沟通卡，敬请谅解；<br />
        4、易褶皱衣物：如西装，短袖等由于包装运输过程可能会有轻微褶皱，收到后请及时挂起，望理解。<br />
        5、自您收到衣物次日算起的48小时内，遇到没洗干净/漏发等问题请勿担心，请扫描包装箱上的售后二维码或者联系美家洁在线客服，我们将第一时间为您解决。<br />
      </div>
    </titleModule>

    <titleModule :title="'特别说明'" class="maTo20" style="margin-bottom: 3rem">
      <div style="line-height: 1.5em">
        特殊时期温馨提醒：出门戴口罩，回家勤洗手，安全你我他！<br />
        【无接触取送】：取送上门前电话沟通，约定指定地点小哥距您1.5米放置包裹<br />
        【衣物灭菌】：专业环保消毒剂，外加定时高温烘干消毒，呵护您的爱衣<br />
        【密封包装】：合格衣物经过自动塑封机一次性无菌防尘袋包装，再装入一次性定制纸箱中胶带密封<br />
        如大型地毯、大型公仔、大件窗帘等超过包邮价的部分，需要您支付快递费用
      </div>
    </titleModule>

    <!-- orderView -->
    <div>
      <div class="cartDiv" ref="cartDiv">
        <div
          class="centerDiv pflex"
          style="justify-content: space-between; padding-bottom: 0.25rem"
        >
          <div>
            <div>
              <span>快递费：</span>
              <span class="orange">¥ {{ expense }}</span>
            </div>
            <div class="miniText maTo20">
              <span>优惠</span>
              <span class="orange">{{ preferentialAmount }}元</span>
            </div>
          </div>
          <div class="pflex">
            <div
              class="orange"
              style="margin-right: 0.25rem; font-size: 0.35rem"
            >
              <span
                v-if="
                  parseFloat(prepaidAmount) +
                    parseFloat(balance) +
                    parseFloat(expense) >
                  0
                "
                >¥
                {{
                  parseFloat(prepaidAmount) +
                  parseFloat(balance) +
                  parseFloat(expense)
                }}</span
              >
              <span v-else>¥ 0</span>
            </div>
            <div>
              <div class="orderButton mainBgc" @click.stop="order">去下单</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <couponChoose
      :showCouponChoose="showCouponChoose"
      :couponList="couponList"
      :totalAmount="totalAmount"
      @closeChoose="closeCouponChoose"
    ></couponChoose>
  </div>
</template>

<script>
import orderAddress from "./orderChooseAddress";
import timeDrawer from "./timeDrawer";
import titleModule from "@/components/titleModule";
import couponChoose from "./couponChoose";
import { mapState } from "vuex";
// import Qs from "qs";
export default {
  data() {
    return {
      timeSelect: {},
      remark: "",
      cartHeight: 0,
      addressList: [],
      cartList: [],
      totalAmount: 0.0, // 总价格
      preferentialAmount: 0, // 优惠金额
      expense: 0, // 快递费
      goodsAmount: 0,
      balance: 0,
      prepaidAmount: 0,
      code: "",
      couponList: [],
      useCouponList: [],
      showCouponChoose: false,
      scrollTop: 0,
      couponAmount: 0,
      haveSupplementGood: false,
      canotBeynond: false, // 是否跨省
      canBeyondProvince: ["北京市,天津市,河北省", "江苏省,浙江省,上海市"], // 跨省组合
    };
  },
  mounted() {
    const that = this;
    that.cartHeight = this.$refs.cart;
    that.http
      .get("address/list.json", {
        token: that.$store.state.token,
      })
      .then((res) => {
        that.addressList = res.data.addressVOList;
        that.http
          .get("cart/list.json", {
            token: that.$store.state.token,
          })
          .then((res) => {
            that.cartList = res.data.cartVOList;
            that.balance = that.$store.state.userInfo.balance;
            if (that.cartList.length == 0) {
              that.toPage("index");
            } else {
              let showPopup = false;
              for (let i of that.cartList) {
                if (i.id == that.envConfig().supplementGoodId) {
                  showPopup = true;
                }
                that.goodsAmount = that.goodsAmount + i.goodsNum * i.vipPrice;
                that.goodsAmount.toFixed(2);
                that.totalAmount = that.totalAmount + i.goodsNum * i.vipPrice;
                that.totalAmount.toFixed(2);
                that.preferentialAmount =
                  that.preferentialAmount + (i.price - i.vipPrice) * i.goodsNum;
                that.preferentialAmount.toFixed(2);
                if (
                  (that.totalAmount >= 68 && that.cartList[0].isNotMail == 0) ||
                  (that.totalAmount >= 399 && that.cartList[0].isNotMail == 1)
                ) {
                  that.expense = 0;
                } else {
                  if (that.cartList[0].isNotMail == 0) {
                    that.expense = 30;
                  }
                  if (that.cartList[0].isNotMail == 1) {
                    that.expense = 40;
                  }
                }
              }
              that.prepaidAmount = that.totalAmount - that.balance;
              if (showPopup) {
                that.expense = 0;
                that.haveSupplementGood = true;
                that.$dialog.alert({
                  title: "温馨提示",
                  message: "您的购物车中内含【补价专拍】商品，不会产生物流哦",
                  confirmButtonColor: that.common.mainColor,
                });
              }
            }
          });
      });
  },
  methods: {
    closeCouponChoose() {
      this.showCouponChoose = false;
      this.amountCom();
    },
    toChoose() {
      this.showCouponChoose = true;
    },
    maxCouponGroup() {
      const that = this;
      if (that.couponList.length > 0) {
        let reuseCouponList = []; // 可叠加优惠券数组
        let cannotReuseCouponList = []; // 不可叠加优惠券数组
        let exReuse = {}; // 最佳可叠加券组合方式
        let exnotReuse = {}; // 最佳不可叠加券组合方式
        for (let i of that.couponList) {
          if (i.limitAmount <= that.totalAmount) {
            if (i.isReuse == 1) {
              reuseCouponList.push(i);
            } else {
              cannotReuseCouponList.push(i);
            }
          }
        }
        // 如果可叠加券存在，计算其最优解
        if (reuseCouponList.length > 0) {
          let canReuseprice = [];
          for (var a2 = []; a2.push([]) < reuseCouponList.length; );
          var l = Math.pow(2, reuseCouponList.length) - 1;
          for (var i = 1; i <= l; i++) {
            var t = [];
            for (var s = i, k = 0; s > 0; s >>= 1, k++)
              if (s & (1 == 1)) t.push(reuseCouponList[k]);
            a2[t.length - 1].push(t);
          }
          for (let i of a2) {
            for (let j of i) {
              let jprice = 0;
              for (let k of j) {
                jprice += k.amount;
                // canReuseprice.push(orderPrice-jprice)
              }
              canReuseprice.push({
                method: j,
                price: Math.abs(that.totalAmount - jprice),
                couponAmountSum: jprice,
              }); // 所有的组合方式及与商品价格差的绝对值
            }
          }
          canReuseprice.sort(function (a, b) {
            return a.price - b.price;
          });
          exReuse = canReuseprice[0]; // 最佳的可复用红包
        }
        // 如果存在不可叠加券，计算最优解
        if (cannotReuseCouponList.length > 0) {
          let cannotReuseprice = [];
          for (let j of cannotReuseCouponList) {
            let jprice = Math.abs(that.totalAmount - j.amount);
            cannotReuseprice.push({
              method: j,
              price: jprice,
              couponAmountSum: j.amount,
            });
            cannotReuseprice.sort(function (a, b) {
              return a.price - b.price;
            });
          }
          exnotReuse = cannotReuseprice[0]; // 最佳的不可复用红包
        }
        // 最终判断（根据可叠加券和不可叠加券的最优解作出综合判断）
        if (cannotReuseCouponList.length > 0 && reuseCouponList.length > 0) {
          if (exnotReuse.price >= exReuse.price) {
            // 选择可叠加券组合
            that.useCouponList = exReuse.method;
          } else {
            // 选择最优不可叠加券
            that.useCouponList = [exnotReuse.method];
          }
        } else {
          // 只有可叠加券
          if (cannotReuseCouponList.length == 0 && reuseCouponList.length > 0) {
            that.useCouponList = exReuse.method;
          }
          // 只有不可叠加券
          if (cannotReuseCouponList.length > 0 && reuseCouponList.length == 0) {
            that.useCouponList = [exnotReuse.method];
          }
        }
        that.$store.commit("useCouponListUpdate", that.useCouponList);
        that.amountCom();
        this.$toast("已为您选好红包最佳搭配");
      } else {
        that.$store.commit("useCouponListUpdate", []);
      }
    },
    getTime(chooseTime) {
      this.timeSelect = chooseTime;
    },
    judgeOrder() {
      const that = this;
      let bool = false;
      if (that.haveSupplementGood) {
        let haveOther = false;
        for (let i of that.cartList) {
          if (i.id != that.envConfig().supplementGoodId) {
            haveOther = true;
            break;
          }
        }
        if (haveOther) {
          that.$dialog.alert({
            title: "温馨提示",
            message: "【补价商品】和其他商品不可以同时下单哦",
            confirmButtonColor: that.common.mainColor,
          });
          bool = false;
        } else {
          bool = true;
        }
      } else {
        bool = true;
      }
      return bool;
    },
    // 判断奢护和普洗
    judgeLuxury() {
      const that = this;
      let isSame = true;
      let isLuxury = that.cartList[0].isLuxury;
      for (let i of that.cartList) {
        console.log(i);
        if (i.isLuxury !== isLuxury) {
          isSame = false;
          break;
        }
      }
      console.log(isSame);
      return isSame;
    },
    // 判断是否跨省
    getBeynond(takeAddressVO, sendAddressVO) {
      const that = this;
      let canBeyond = false;
      let canBeyondProvince = that.canBeyondProvince;
      if (takeAddressVO.province == sendAddressVO.province) {
        canBeyond = true;
      } else {
        for (let j of canBeyondProvince) {
          if (
            j.indexOf(sendAddressVO.province) !== -1 &&
            j.indexOf(takeAddressVO.province) !== -1
          ) {
            canBeyond = true;
            break;
          } else {
            canBeyond = false;
          }
        }
      }
      return canBeyond;
    },
    order() {
      const that = this;
      function toOrder() {
        if (that.timeSelect.day) {
          if (
            !that.$store.state.chooseTakeAddress.city ||
            that.addressList.length == 0
          ) {
            that.$dialog.alert({
              title: "提示",
              message: "请选择地址",
              confirmButtonColor: that.common.mainColor,
            });
          } else {
            let couponIds = "";
            let couponAmount = 0;
            if (that.$store.state.useCouponList.length > 0) {
              for (let i of that.$store.state.useCouponList) {
                couponIds = couponIds + "," + i.id;
                couponAmount = couponAmount + i.amount;
              }
              couponAmount = couponAmount.toFixed(2);
              that.couponAmount = couponAmount;
              that.actualAmount = that.actualAmount - couponAmount;
              couponIds = couponIds.substr(1);
            }
            if (
              that.getBeynond(
                that.$store.state.chooseTakeAddress,
                that.$store.state.chooseSendAddress
              )
            ) {
              // console.log(
              //   JSON.stringify({
              //     takeAddressVO: that.$store.state.chooseTakeAddress,
              //     sendAddressVO: that.$store.state.chooseSendAddress,
              //     token: that.$store.state.token,
              //     orderGoodsVOList: that.cartList,
              //     expressFee: that.expense,
              //     actualAmount: that.actualAmount,
              //     goodsAmount: that.goodsAmount,
              //     prepaidAmount: that.prepaidAmount,
              //     takeDate: that.timeSelect.day + "," + that.timeSelect.time,
              //     remark: that.remark,
              //     platform: "wash",
              //     couponIds: "",
              //     orderSource: "gat",
              //   })
              // );
              that.http
                .post("order/zhongxinOrder.json", {
                  takeAddressVO: that.$store.state.chooseTakeAddress,
                  sendAddressVO: that.$store.state.chooseSendAddress,
                  token: that.$store.state.token,
                  orderGoodsVOList: that.cartList,
                  expressFee: that.expense,
                  actualAmount: that.actualAmount,
                  goodsAmount: that.goodsAmount,
                  prepaidAmount: that.prepaidAmount,
                  takeDate: that.timeSelect.day + "," + that.timeSelect.time,
                  remark: that.remark,
                  platform: "wash",
                  couponIds: "",
                  orderSource: "meijiajieH5",
                })
                .then((res) => {
                  let orderRes = res;
                  console.log(orderRes);
                  if (res.data.stateVO.code == 200) {
                    that.redPage("orderList");
                  }
                  // 跳转至拼接url调用关爱通支付

                  // if (that.prepaidAmount <= 0) {
                  //   that.toPage("orderList");
                  // } else {
                  //   that.http
                  //     .get("recharge/prePay.json", {
                  //       token: that.$store.state.token,
                  //       amount: that.totalAmount,
                  //       orderId: orderRes.data.orderVO.orderStateVO.orderId,
                  //       payMethod: "zhpay",
                  //       type: "order",
                  //       zhUserId: that.$store.state.userInfo.zhUserId,
                  //       zhUserType: that.$store.state.userInfo.zhUserType || "",
                  //     })
                  //     .then((payRes) => {
                  //       if (payRes.data.stateVO.code == 200) {
                  //         window.location.href = payRes.data.prePayVO.mwebUrl;
                  //       }
                  //     });
                  // }
                });
            } else {
              that.$dialog.alert({
                title: "温馨提示",
                message:
                  "客户您好，订单省内包邮，如需跨省送回，请下单后联系【在线人工客服】修改地址并支付差额运费。",
                confirmButtonColor: that.common.mainColor,
              });
            }
          }
        } else {
          that.$dialog.alert({
            title: "提示",
            message: "请选择时间",
            confirmButtonColor: that.common.mainColor,
          });
        }
      }
      let isHaveMany = false;
      for (let i of that.cartList) {
        if (i.isNotMail !== that.cartList[0].isNotMail) {
          isHaveMany = true;
          break;
        }
      }
      if (!isHaveMany) {
        if (that.judgeLuxury()) {
          if (that.judgeOrder()) {
            if (that.haveSupplementGood) {
              that.http
                .get("order/list.json", {
                  token: that.$store.state.token,
                  state: 1,
                })
                .then((res) => {
                  if (res.data.orderVOList && res.data.orderVOList.length > 0) {
                    let orderList = res.data.orderVOList;
                    let haveOrder = true;
                    for (let i of orderList) {
                      if (i.status == -1 && i.status >= 5) {
                        haveOrder = false;
                        break;
                      }
                    }
                    if (!haveOrder) {
                      that.$dialog.alert({
                        title: "温馨提示",
                        message: "您没有需要补价的订单哦",
                        confirmButtonColor: that.common.mainColor,
                      });
                    } else {
                      toOrder();
                    }
                  } else {
                    that.$dialog.alert({
                      title: "温馨提示",
                      message: "您没有需要补价的订单哦",
                      confirmButtonColor: that.common.mainColor,
                    });
                  }
                });
            } else {
              toOrder();
            }
          }
        } else {
          that.$dialog.alert({
            title: "温馨提示",
            message: "奢护商品不能和普洗商品同时下单",
            confirmButtonColor: that.common.mainColor,
          });
        }
      } else {
        that.$dialog.alert({
          title: "温馨提示",
          message: "洗衣不能和小商品同时下单",
          confirmButtonColor: that.common.mainColor,
        });
      }
    },
    amountCom() {
      const that = this;
      let couponAmount = 0;
      for (let i of that.$store.state.useCouponList) {
        couponAmount = couponAmount + i.amount;
      }
      couponAmount = couponAmount.toFixed(2);
      that.couponAmount = couponAmount;
      that.prepaidAmount = that.totalAmount - that.balance - couponAmount;
      that.prepaidAmount = that.prepaidAmount.toFixed(2);
    },
    //禁止滚动
    stopScroll() {
      let _scrollTop = document.scrollingElement.scrollTop;
      this.scrollTop = _scrollTop;
      document.body.classList.add("dialog");
      document.body.style.top = -_scrollTop + "px";
      console.log(this.scrollTop);
    },
    // 取消滑动限制
    canScroll() {
      document.body.classList.remove("dialog");
      console.log(this.scrollTop);
      document.scrollingElement.scrollTop = this.scrollTop;
    },
    toRange() {
      this.$router.push({
        name: "iframe",
        query: {
          webSrc: "service_area_zhonghang",
        },
      });
    },
  },
  computed: {
    ...mapState(["chooseTakeAddress"]),
  },
  watch: {
    chooseTakeAddress(newValue) {
      // 处理 chooseTakeAddress 属性变化的逻辑
      console.log(newValue);
    },
    cartList(val) {
      console.log(val);
      if (val.length > 0 && val[0].isNotMail == 0) {
        if (this.$store.state.chooseTakeAddress.city == "深圳市") {
          this.expense = 0;
        } else {
          if (this.totalAmount < 68) {
            this.expense = 30;
          }
        }
      }
    },
  },
  components: {
    orderAddress,
    timeDrawer,
    titleModule,
    couponChoose,
  },
};
</script>

<style scoped>
.leftImg {
  width: 20%;
}

.leftImg > img {
  width: 75%;
}

.middleInfo {
  width: 60%;
  align-items: flex-start;
  padding-left: 0.2rem;
}
.rightNum {
  width: 20%;
}

.cartGoodsDiv {
  padding: 0.15rem 0;
}

.orderButton {
  color: #ffffff;
  padding: 0.22rem 0.3rem;
  border-radius: 0.1rem;
}

.cartDiv {
  width: 100%;
  background: #ffffff;
  padding: 0.2rem 0;
  padding-bottom: env(safe-area-inset-bottom);
  border-top: #ebe6e6 solid 0.02rem;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}

.couponDiv {
  width: 100%;
  background: white;
  padding: 0.4rem 0;
  /* border:#e9e9e9 solid 0.01rem */
  box-shadow: 0px 0.05rem 0.13rem #d8d8d8;
}
</style>
