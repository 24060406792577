<template>
  <div class="content commonText">
    <!-- headTab -->
    <van-tabs
      v-model="tabActive"
      :color="common.mainColor"
      @change="changeTab"
      sticky
      :offset-top="$store.state.navHeight"
    >
      <van-tab v-for="(item, index) in tab" :key="index" :title="item.tab">
        <!-- orderList -->
        <div style="padding-bottom: 2rem" v-if="orderList.length > 0">
          <div v-for="(item, index) in orderList" :key="index">
            <oneOrder
              :orderInfo="item"
              @updateOrderList="getOrderList()"
              @pay="toPay"
            ></oneOrder>
          </div>
        </div>
        <div v-else>
          <van-empty description="您还没有订单" />
        </div>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import oneOrder from "./oneOrder";
export default {
  name: "App",
  data() {
    return {
      tabActive: 0,
      tab: [
        { tab: "进行中", state: 1 },
        { tab: "已完成", state: 2 },
      ],
      orderList: [],
      code: "",
    };
  },
  components: {
    oneOrder,
  },
  mounted() {
    this.getOrderList();
  },
  methods: {
    toPay(orderInfo) {
      const that = this;
      that.http
        .get("recharge/prePay.json", {
          token: that.$store.state.token,
          amount: orderInfo.topaidAmount,
          orderId: orderInfo.id,
          payMethod: "zhpay",
          type: "order",
          zhUserId: that.$store.state.userInfo.zhUserId,
          zhUserType: that.$store.state.userInfo.zhUserType || "",
        })
        .then((payRes) => {
          if (payRes.data.stateVO.code == 200) {
            window.location.href = payRes.data.prePayVO.mwebUrl;
          }
        });
    },
    // changeTab
    changeTab() {
      this.getOrderList();
    },
    getOrderList() {
      const that = this;
      that.http
        .get("order/list.json", {
          token: that.$store.state.token,
          // openId: that.$store.state.openId,
          state: that.tab[that.tabActive].state,
        })
        .then((res) => {
          that.orderList = res.data.orderVOList;
        });
    },
  },
};
</script>