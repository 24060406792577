<template>
  <div class="content commonText">
    <!-- status -->
    <div style="background: white; padding: 0.3rem 0">
      <div style="width: 100%">
        <van-steps
          :active="orderInfo.status - 1"
          :active-color="common.mainColor"
        >
          <van-step>待付款</van-step>
          <van-step>待取件</van-step>
          <van-step>待入厂</van-step>
          <van-step>处理中</van-step>
          <van-step>待收货</van-step>
          <van-step>待评价</van-step>
        </van-steps>
      </div>
    </div>

    <titleModule :title="'进度信息'" class="maTo20">
      <div
        v-if="orderInfo.orderStateVO"
        @click="toPage('statusList', { orderId: orderInfo.id })"
      >
        <div style="line-height: 1.5em">
          {{ orderInfo.orderStateVO.remark }}
        </div>
        <div class="maTo20">{{ orderInfo.orderStateVO.createTime }}</div>
      </div>
    </titleModule>

    <titleModule :title="'下单地址'" class="maTo20" :slotWidth="'100'">
      <div class="addDiv">
        <div class="centerDiv pflex">
          <div class="left pflex">
            <img src="@/images/addr.png" style="width: 100%" alt="" />
          </div>
          <div class="middle">
            <div class="centerDiv" v-if="orderInfo.takeAddressVO">
              <div style="line-height: 1.5em">
                {{ orderInfo.takeAddressVO.city
                }}{{ orderInfo.takeAddressVO.district
                }}{{ orderInfo.takeAddressVO.building
                }}{{ orderInfo.takeAddressVO.address }}
              </div>
              <div class="maTo20 miniText">
                {{ orderInfo.takeAddressVO.name }}
                {{ orderInfo.takeAddressVO.telphone }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- line -->
      <div class="line centerDiv"></div>

      <!-- send -->
      <div class="addDiv">
        <div class="centerDiv pflex">
          <div class="left pflex">
            <img src="@/images/addr-song.png" style="width: 100%" alt="" />
          </div>
          <div class="middle">
            <div class="centerDiv" v-if="orderInfo.sendAddressVO">
              <div style="line-height: 1.5em">
                {{ orderInfo.sendAddressVO.city
                }}{{ orderInfo.sendAddressVO.district
                }}{{ orderInfo.sendAddressVO.building
                }}{{ orderInfo.sendAddressVO.address }}
              </div>
              <div class="maTo20 miniText">
                {{ orderInfo.sendAddressVO.name }}
                {{ orderInfo.sendAddressVO.telphone }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </titleModule>

    <titleModule :title="'订单商品'" class="maTo20" :slotWidth="'100'">
      <div v-if="orderInfo.orderGoodsVOList">
        <div
          v-for="(item, index) in orderInfo.orderGoodsVOList"
          :key="index"
          class="cartGoodsDiv"
          style="border-bottom: #f5f1f1 solid 0.01rem"
        >
          <div class="pflex centerDiv">
            <div class="leftImg pflex">
              <img :src="item.picurl" alt="" />
            </div>
            <div class="middleInfo cflex">
              <div>{{ item.name }}</div>
              <div class="orange maTo20">¥{{ item.vipPrice }}</div>
            </div>
            <div class="rightNum">数量 x {{ item.goodsNum }}</div>
          </div>
          <div
            class="maTo20 miniText centerDiv"
            v-if="orderInfo.orderGoodsVOList[index].defectiveRemark"
          >
            <div class="orange" style="line-height: 1.5em">
              工厂反馈：{{ orderInfo.orderGoodsVOList[index].defectiveRemark }}
            </div>
          </div>
        </div>
      </div>
    </titleModule>

    <titleModule :title="'订单信息'" class="maTo20" :slotWidth="'100'">
      <div class="centerDiv miniText">
        <div class="pflex bt">
          <div>订单编号：</div>
          <div>{{ orderInfo.orderNo }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>下单时间</div>
          <div>{{ orderInfo.createTime }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>取衣时间</div>
          <div>{{ orderInfo.takeDate }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>衣服预估件数</div>
          <div>{{ orderInfo.goodsNum }}件</div>
        </div>
        <div class="pflex bt maTo20">
          <div>实际衣物数量</div>
          <div>{{ orderInfo.actualGoodsNum }}件</div>
        </div>
        <div class="pflex bt maTo20">
          <div>商品金额</div>
          <div>¥ {{ orderInfo.goodsAmount }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>运费</div>
          <div>¥ {{ orderInfo.expressFee }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>红包</div>
          <div>¥ {{ orderInfo.couponAmount }}</div>
        </div>
        <div class="pflex bt maTo20">
          <div>预付金额</div>
          <div>¥ {{ orderInfo.topaidAmount }}</div>
        </div>
      </div>
    </titleModule>

    <titleModule :title="'时效&运费说明'" class="maTo20">
      <div style="line-height: 1.5em">
        【洗护时效】<br />
        1、常规洗涤周期:普通衣物订单3-5天左右，含鞋子订单5-7天左右，难处理污渍/特殊问题的订单延时1-2天左右。<br />
        2、旺季洗衣周期:普通衣物订单5-7天左右，含鞋子订单7-10天左右，难处理污渍/特殊问题的订单延时1-2天左右。备注:洗涤周期按订单中涉及最高时长为准，暂无付费加急业务。<br />
        【运费说明】<br />
        1、订单满88元包邮，除西藏、青海、内蒙古及港澳台地区外全国可用；<br />
        2、因个人原因造成快递退回或订单取消，需要您承担来回运费20元;<br />
        3、因个人原因造成订单部分衣物不洗，不洗衣物金额大于等于订单金额50%，需要您承担运费10元;<br />
        4、因工厂原因无法清洗，则无需您承担运费。<br />
      </div>
    </titleModule>

    <div style="width: 100%; margin-bottom: 2rem">
      <!-- <div
        class="centerDiv pflex buttomButton maTo20 ceButton"
        @click="toPage('ce')"
      >
        联系客服
      </div> -->
      <div
        class="centerDiv pflex buttomButton maTo20 ceButton"
        v-if="orderInfo.status < 3 && orderInfo.status !== -1"
        @click="cancelOrder"
      >
        取消订单
      </div>
    </div>
    <!-- bottomButtonGroup -->
    <div
      class="bottomButtonGroup"
      v-if="orderInfo.topaidAmount > 0 && orderInfo.status !== -1"
    >
      <div
        class="centerDiv pflex buttomButton mainBgc"
        style="color: white"
        @click="toPay"
      >
        付款 ¥{{ orderInfo.topaidAmount }}
      </div>
    </div>
  </div>
</template>

<script>
import titleModule from "@/components/titleModule";
export default {
  data() {
    return {
      active: 0,
      orderInfo: { status: 1 },
      orderId: "",
      code: "",
      type: 0,
    };
  },
  activated() {
    const that = this;
    if (that.$route.params.orderId) {
      that.orderId = that.$route.params.orderId;
      if (that.$route.params.type) {
        that.type = that.$route.params.type;
      }
    }
    if (that.$route.params.orderId || that.orderId != "") {
      that.http
        .get("order/detail.json", {
          token: that.$store.state.token,
          orderId: that.orderId,
        })
        .then((res) => {
          that.orderInfo = res.data.orderVO;
        });
    } else {
      that.toPage("orderList");
    }
  },
  components: {
    titleModule,
  },
  methods: {
    toPay() {
      const that = this;
      that.http
        .get("recharge/prePay.json", {
          token: that.$store.state.token,
          amount: that.orderInfo.topaidAmount,
          orderId: that.orderInfo.id,
          payMethod: "zhpay",
          type: "order",
          zhUserId: that.$store.state.userInfo.zhUserId,
          zhUserType: that.$store.state.userInfo.zhUserType || "",
        })
        .then((payRes) => {
          if (payRes.data.stateVO.code == 200) {
            window.location.href = payRes.data.prePayVO.mwebUrl;
          }
        });
    },
    cancelOrder() {
      const that = this;
      that.$dialog
        .confirm({
          title: "提示",
          message: "是否取消订单,如果已支付，钱款稍后将会退回",
          confirmButtonColor: that.common.mainColor,
        })
        .then(() => {
          // on confirm
          that.http
            .get("order/status.json", {
              token: that.$store.state.token,
              // openId: that.$store.state.openId,
              orderId: that.orderInfo.id,
              status: -1,
            })
            .then(() => {
              if (that.$route.params.orderId || that.orderId != "") {
                that.http
                  .get("order/detail.json", {
                    token: that.$store.state.token,
                    // openId: that.$store.state.openId,
                    orderId: that.orderId,
                  })
                  .then((res) => {
                    that.orderInfo = res.data.orderVO;
                  });
              } else {
                that.toPage("orderList");
              }
            });
        })
        .catch(() => {
          // on cancel
        });
    },
  },
};
</script>

<style scoped>
.addDiv {
  background: white;
  padding: 0.3rem 0;
  width: 100%;
}
.left {
  width: 15%;
}

.middle {
  width: 85%;
}

.right {
  width: 10%;
  justify-content: flex-end;
}

.line {
  background: #e4e4e4;
  height: 0.01rem;
}

.leftImg {
  width: 20%;
}

.leftImg > img {
  width: 75%;
}

.middleInfo {
  width: 60%;
  align-items: flex-start;
  padding-left: 0.2rem;
}
.rightNum {
  width: 20%;
}

.cartGoodsDiv {
  padding: 0.15rem 0;
}

.bottomButtonGroup {
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: 0.3rem 0;
  background: white;
}

.buttomButton {
  padding: 0.3rem 0;
  border-radius: 0.15rem;
}

.ceButton {
  background: #fff;
  box-shadow: 0px 0px 10px #d6d6d6;
}

.cancel {
  border: solid 1px #a3a3a3;
}
</style>