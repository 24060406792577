<template>
  <div class="content commonText">
    <van-form @submit="onSubmit">
      <van-field
        v-model="infoObj.username"
        name="name"
        label="联系人"
        placeholder="请输入联系人姓名"
        :rules="[{ required: true, message: '请填写用户名' }]"
      />
      <van-field
        v-model="infoObj.tel"
        name="telphone"
        label="电话"
        placeholder="请输入电话"
        type="tel"
        :rules="[{ required: true, message: '请填写电话' }]"
      />
      <!-- <van-field
        label="收货地址"
        name="address"
        :value="
          $store.state.chooseMap.building
            ? $store.state.chooseMap.building
            : '点击选择地址'
        "
        type="textarea"
        readonly
        :rules="[{ required: true, message: '请选择地址' }]"
      /> -->
      <van-field
        label="省市区"
        name="province"
        :value="
          infoObj.province.province
            ? infoObj.province.province +
              infoObj.province.city +
              infoObj.province.district
            : '点击选择省市区'
        "
        placeholder="点击选择省市区"
        readonly
        @click="openPicker"
        :rules="[{ required: true, message: '请选择省市区' }]"
      />
      <van-field
        v-model="infoObj.building"
        name="address"
        label="街道/小区"
        placeholder="请输入街道/小区"
        :rules="[{ required: true, message: '请填写街道/小区' }]"
      />
      <van-field
        v-model="infoObj.address"
        name="address"
        label="门牌号"
        placeholder="请输入具体地址"
        :rules="[{ required: true, message: '请填写具体地址' }]"
      />
      <van-field name="radio" label="是否常用">
        <template #input>
          <van-radio-group
            v-model="isCommon"
            direction="horizontal"
            :checked-color="common.mainColor"
          >
            <van-radio name="1">是</van-radio>
            <van-radio name="0">否</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <div style="margin-top: 0.3rem" class="centerDiv">
        <van-button
          :color="common.mainColor"
          block
          type="info"
          native-type="submit"
          >提交</van-button
        >
      </div>
    </van-form>
    <van-action-sheet v-model="isShowPicker">
      <van-area
        @cancel="openPicker"
        @confirm="confirm"
        title="省市区"
        :area-list="areaList"
        :columns-placeholder="['请选择', '请选择', '请选择']"
        value="110101"
      />
    </van-action-sheet>
  </div>
</template>

<script>
import { areaList } from "@vant/area-data";
export default {
  data() {
    return {
      isCommon: "1",
      addressChoose: {},
      isEdit: false,
      id: 0,
      areaList,
      infoObj: {
        username: "",
        tel: "",
        address: "",
        province: {},
        building: "",
      },
      isShowPicker: false,
    };
  },
  mounted() {
    const that = this;
    if (that.$route.params.telphone) {
      that.isEdit = true;
      that.infoObj.tel = that.$route.params.telphone;
      that.infoObj.username = that.$route.params.name;
      that.infoObj.building = that.$route.params.building;
      that.infoObj.address = that.$route.params.address;
      that.id = that.$route.params.id;
      // that.$store.commit("chooseMapUpdate", {});
    } else {
      that.isEdit = false;
    }
  },
  methods: {
    openPicker() {
      this.isShowPicker = !this.isShowPicker;
    },
    confirm(val) {
      if (val[0] && val[1] && val[2] && val[2].name) {
        this.infoObj.province = {
          province: val[0].name,
          city: val[1].name,
          district: val[2].name,
        };
        this.openPicker();
      } else {
        this.$toast("省市区填写不全");
      }
    },
    onSubmit() {
      const that = this;
      // const addressInfo = that.$store.state.chooseMap;
      const reg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
      if (!reg.test(that.infoObj.tel)) {
        that.$toast.fail("手机号有错误哦");
      } else {
        if (
          that.infoObj.province.city &&
          that.infoObj.province.province &&
          that.infoObj.province.district
        ) {
          let params = {
            token: that.$store.state.token,
            address: that.infoObj.address,
            building: that.infoObj.building,
            city: that.infoObj.province.city,
            district: that.infoObj.province.district,
            isDefault: that.isCommon,
            name: that.infoObj.username,
            province: that.infoObj.province.province,
            telphone: that.infoObj.tel,
            // latitude: addressInfo.latitude,
            // longitude: addressInfo.longitude,
            id: that.id,
          };
          that.http.get("address/save.json", params).then(() => {
            that.back();
          });
        } else {
          that.$toast.fail("请选择省市区");
        }
      }
    },
    openMap() {
      this.toPage("map");
    },
  },
};
</script>
