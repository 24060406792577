<template>
  <div class="content commonText" :style="'background:' + bgColor">
    <div class="head">
      <div class="centerDiv">
        {{ title }}
      </div>
    </div>
    <div>
      <div
        class="centerDiv"
        style="padding: 0.2rem 0"
        :style="'width:' + slotWidth + '%'"
      >
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    bgColor: {
      type: String,
      default: "white",
    },
    slotWidth: {
      type: String,
      default: "90",
    },
  },
};
</script>

<style scoped>
.head {
  padding: 0.3rem 0;
  border-bottom: solid #e4e4e4 1px;
}
</style>